/* Customize your main colors in :root variables */
:root {
  --main-background-color: #3CFF99;
  --card-background-color: #dcdddd;
  --card-background-lighter-color: #dcdddd;
  --main-text-color:#acacac;
  --title-text-color:#d4b04c;
}

body{
  background-color:var(--main-background-color);
  font-family: 'orbitron' !important;
  font-size: 1.2em;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}



